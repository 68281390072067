import {Heading, Box, Flex} from "@chakra-ui/react";
import React, {useContext, useMemo} from "react";

import RadarVocabMulti from "../../../graph/RadarVocabMulti";
import {getNameFromTagId, LIST_ALL_VOCAB} from "../../tools";
import PersonalizedContentContext from "../../../../context/PersonalizedContent";
import {LOG} from "../../../tools";
import TagMenuSelect from "../TagMenuSelect";


const TagsContentVocab = ({listContentVocab})=> {

    const datasetsRaw = useMemo(()=>{
       return  listContentVocab.map((dataVocabs)=>{
            const name = getNameFromTagId(dataVocabs["id"])
        let dataset_raw_datainfo = {}
            if (dataVocabs!= null){

                dataVocabs["d"].forEach((vocabValue)=>{
                const tag_id = vocabValue[0]
                const proportion = vocabValue[1]/10000
                const diff_ratio = vocabValue[2]/100
                dataset_raw_datainfo[tag_id] = {
                    "p":proportion,
                    "d":diff_ratio
                }
                })
            }
            return {label:name,jsonTagIdToData: dataset_raw_datainfo}
        })

    }, [listContentVocab])
    LOG("datasetsRaw",datasetsRaw)

    //check tagid visible for vocabulary
    const {jsonTagVisible} = useContext(PersonalizedContentContext);
   const tagType = "V"
    const listTagJson = LIST_ALL_VOCAB
    const objectSetTagVisible = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listTagJson.map(p => p.id)))
                                :new Set(listTagJson.map(p => p.id))
    }, [jsonTagVisible,listTagJson])
    const listTagJsonShown = useMemo(()=>{
        let listTagJsonShown_tmp =  listTagJson.filter((tagJson)=> objectSetTagVisible.has(tagJson.id))
        listTagJsonShown_tmp.sort((a,b) => {
            if (datasetsRaw[0].hasOwnProperty("jsonTagIdToData")){
                const diff_a = datasetsRaw[0]["jsonTagIdToData"].hasOwnProperty(a.id)?datasetsRaw[0]["jsonTagIdToData"][a.id]["d"]:0
            const diff_b = datasetsRaw[0]["jsonTagIdToData"].hasOwnProperty(b.id)?datasetsRaw[0]["jsonTagIdToData"][b.id]["d"]:0
            return diff_b - diff_a
            }else{return 0}
        })
        return listTagJsonShown_tmp
    }, [listTagJson,objectSetTagVisible,datasetsRaw])
    const listTagIdShown = useMemo(()=>{
        return listTagJsonShown.map(t => t.id)
    }, [listTagJsonShown])


    return <Box>
        <Heading textDecoration={"underline"} letterSpacing={2} textDecorationColor={"text.50"} as={"h3"}
                     fontSize={['16px','20px']} mb={2}>Vocabulaire des trois dernières semaines</Heading>

        <Flex justifyContent={"flex-start"} alignItems={"center"} mt={5} p={2} flexWrap={"wrap"}>
            <Box p={2}  sx={{fontSize:'20px',lineHeight:1.4,color:'text.500',fontWeight:'normal'}}>Vous pouvez sélectionner les thématiques que vous souhaitez afficher : </Box>
            <TagMenuSelect listTag={LIST_ALL_VOCAB}  tagType={"V"} />
        </Flex>
        <Box>
            <RadarVocabMulti
                datasetsRaw = {datasetsRaw}
                listTagIdToKeep={listTagIdShown}
            />
        </Box>
    </Box>
}


export default TagsContentVocab