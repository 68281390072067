import * as React from "react"

import Layout from "../components/layout"
import {Container} from "@chakra-ui/react";
import {useContext, useEffect} from "react";
import DataLoadContext from "../context/DataLoadContext";
import {getDataPageLoad} from "../components/api/getData";
import PersonsPage from "../components/persons/PersonsPage";

const Personnalite = ({ }) => {
    const {updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("personnalite").then((d) =>{
        updateLastUpdate(d)
    });
  }, []);
  return (
    <Layout  title={"Les personnalités politiques"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
        <PersonsPage/>
        </Container>
    </Layout>
  )
}

export default Personnalite
