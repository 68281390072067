import {Box} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import TagsGraphNbByDay from "./TagsGraphNbByDay";
import TagsContentVocab from "./TagsContentVocab";
import {LOG} from "../../../tools";
import DataLoadContext from "../../../../context/DataLoadContext";
import {
        getDataPageLoad,
        getListDataContentSummaryTag
} from "../../../api/getData";
import ErrorBoundary from "../../../tools/ErrorBoundary";



const TagsContentSummary = ({listTagShown})=> {


    const {lastUpdate,updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("pageTag").then((d) =>{
        updateLastUpdate(d)
        });
      }, []);
    const nameFAnalyzer = "PageTags"
    const lastUpdatePageTag = lastUpdate.hasOwnProperty(nameFAnalyzer)?lastUpdate[nameFAnalyzer]:null


    const [jsonContentSummaryByTag, setJsonContentSummaryByTag] = useState({})
    useEffect(()=>{
        let DO_update = false
        LOG("useEffect TagsContentSummary")
        LOG("lastUpdatePageTag",lastUpdatePageTag)

        if(lastUpdatePageTag!=null) {
            let listTagIdToLoadData = []
            listTagShown.map((t) => {
                if (jsonContentSummaryByTag.hasOwnProperty(t.id) == false) {
                listTagIdToLoadData.push(t.id)
                DO_update = true
                }
            })
            if(listTagIdToLoadData.length>0){
                getListDataContentSummaryTag(listTagIdToLoadData,lastUpdatePageTag).then((listDataContentSummary) =>{
                LOG("getListDataContentSummaryTag",listDataContentSummary)
                if(listDataContentSummary !=null){
                    // SET D
                    let jsonContentSummaryByTagNew = {...jsonContentSummaryByTag}
                    listDataContentSummary.map((contentSummary)=>{
                            const tag_id = contentSummary["id"]
                        jsonContentSummaryByTagNew[tag_id] = contentSummary
                    })
                    setJsonContentSummaryByTag(jsonContentSummaryByTagNew)


                }
            });
            }
        }
    },[listTagShown,lastUpdatePageTag])

    const listGraphNb = useMemo(()=>{
        return listTagShown.filter((tagJson)=>jsonContentSummaryByTag.hasOwnProperty(tagJson.id))
            .map((tagJson) => {
            const tagId = tagJson.id
             return {d:jsonContentSummaryByTag[tagId]["g_nb"],id:tagId}

        })
    },[jsonContentSummaryByTag,listTagShown])
    const listContentVocab = useMemo(()=>{
        return listTagShown.filter((tagJson)=>jsonContentSummaryByTag.hasOwnProperty(tagJson.id))
            .map((tagJson) => {
                const tagId = tagJson.id
            return {d:jsonContentSummaryByTag[tagId]["v"],id:tagId}
        })
    },[jsonContentSummaryByTag,listTagShown])
    return <Box p={1}>
        {listGraphNb.length>0 && <ErrorBoundary><TagsGraphNbByDay listGraphNb={listGraphNb}/></ErrorBoundary>}
        {listGraphNb.length>0 && <ErrorBoundary><TagsContentVocab listContentVocab={listContentVocab}/></ErrorBoundary>}

    </Box>
}


export default TagsContentSummary