import React, {useContext, useMemo, useState} from "react";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import TagMenuSelect from "../tag/components/TagMenuSelect";
import TagTableSimple from "../tag/components/TagTableSimple";
import {motion} from "framer-motion";
import {LIST_ALL_PERSON} from "../tag/tools";
import {LOG} from "../tools";
import PersonalizedContentContext from "../../context/PersonalizedContent";
import TagsGraphNbByDay from "../tag/components/multiTags/TagsGraphNbByDay";
import TagsContentVocab from "../tag/components/multiTags/TagsContentVocab";
import TagsContentSummary from "../tag/components/multiTags/TagsContentSummary";

const MotionHeading = motion(Heading)

const PersonsPage = ({})=>{

    const listPersonJson = LIST_ALL_PERSON
      const {jsonTagVisible} = useContext(PersonalizedContentContext);
   const tagType = "P"
    const objectSetTagVisible = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listPersonJson.map(p => p.id)))
                                :new Set(listPersonJson.map(p => p.id))
    }, [jsonTagVisible,listPersonJson])
    const listPersonJsonShown = useMemo(()=>{
        return listPersonJson.filter((personJson)=> objectSetTagVisible.has(personJson.id))
    }, [listPersonJson,objectSetTagVisible])

    return <Box>
                <Flex  width={["100%"]} m={2} mt={5} p={2} sx={{justifyContent: 'flex-start'}}>
                 <Box sx={{width:'15px', bg:'bg.900'}}/>
                <MotionHeading as={"h2"}  sx={{fontSize:'20px',lineHeight:1.4,color:'text.500'}} animate={{x:-5}}>Les Personnalités politiques</MotionHeading>
                </Flex>

        <Text p={3} sx={{fontSize:'20px',lineHeight:1.4,color:'text.500'}} animate={{x:-5}}>Un ensemble important de personnalités politiques est suivi sur les réseaux.
                La-Voix-Des-Elections les agrège pour donner une visibilité de l'activité qui entoure
                ces différents personnages et comprendre ce qui les différencie</Text>
        <Flex justifyContent={["center","center","flex-start"]} alignItems={"center"} mt={1} mb={3} p={0} flexWrap={"wrap"}>
            <Box p={2}  sx={{fontSize:'20px',lineHeight:1.4,color:'text.500',fontWeight:'bold'}}>Vous pouvez sélectionner les personnalités que vous souhaitez suivre : </Box>
            <TagMenuSelect listTag={listPersonJson}  tagType={"P"} />
        </Flex>

        <TagsContentSummary listTagShown={listPersonJsonShown}/>
        <Flex justifyContent={"space-between"} mt={5} p={0} flexWrap={"wrap"}>
            <Heading textDecoration={"underline"} letterSpacing={2} textDecorationColor={"text.50"} as={"h3"}
                     fontSize={['16px','20px']} mb={2}>Activité des 24 dernières heures des personnalités politiques</Heading>
        </Flex>
        <TagTableSimple listTagShown={listPersonJsonShown}/>
    </Box>
}

export default PersonsPage