import {Heading,Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {addMinutes, LOG} from "../../../tools";
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import {getNameFromTagId} from "../../tools";
import theme, {LIST_COLOR_NAME} from "../../../../@chakra-ui/gatsby-plugin/theme";

const TagsGraphNbByDay = ({listGraphNb})=> {
    //The pattern yyyy-MM-dd isn't an officially supported format for Date
    const startDate = new Date(listGraphNb.length>0?listGraphNb[0]["d"].sd.replace(/-/g, "/"):"")
        const [listLabel,setListLabel] = useState([])
    const [datasetGraph,setDatasetGraph] = useState([])
    const stepWindow = 60*24
        useEffect(()=>{
            let list_label = []
        listGraphNb[0]["d"]["d"].forEach((value,i)=>{
            list_label.push(
                addMinutes(startDate,i*stepWindow) )
        })
            setListLabel(list_label)

            setDatasetGraph(listGraphNb.map((tagInfoGraph,numTag)=>{
            return {
             type:'line',
        label: getNameFromTagId(tagInfoGraph.id),
        data: tagInfoGraph["d"]["d"],
        fill: false,
        borderColor: theme.colors[LIST_COLOR_NAME[numTag%LIST_COLOR_NAME.length]][500],
                backgroundColor:theme.colors[LIST_COLOR_NAME[numTag%LIST_COLOR_NAME.length]][300],
        tension: 0.3,
      }
        }))


        },[listGraphNb])
    let json_test = {}

    const data = {
        labels:listLabel,
      datasets: datasetGraph
    };

    LOG("listLabel",listLabel)
    const options = {
   responsive: true,
    maintainAspectRatio: false,
     /*   animation: {
        duration: 1000
    },*/
    plugins:{
        legend:{
            position:'bottom'
        },
        tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            /*callbacks: {
                title: function(tooltipItem, data) {
                    return  ` ${tooltipItem[0].label}`
                },
            }*/

        }
    },
  scales: {
      x:{
        grid: {display:false,drawBorder: true,},
          type: 'timeseries',
        distribution: 'linear',
          min:startDate,
          time: {
          unit:'day',stepSize:7,
          displayFormats: { day:'dd MMM',minute: 'h:MM',hour: "dd/MM-HH:mm"},
            ticks:{source:'auto',}
            }
      },
    y: {
      display: true,
        ticks: {  beginAtZero: false, display: false     },
        grid: {  display:false,   drawBorder: false,  },
      title: {    display: false}
    },
  },
        layout:{
        padding:{  left: 0, right: 0, top: 0, bottom: 0, }
        }
};

    return <Box>
        <Heading textDecoration={"underline"} letterSpacing={2} textDecorationColor={"text.50"} as={"h3"}
                     fontSize={['16px','20px']} mb={2}>Nombre de tweets chaque jour</Heading>

        <Box height={400}>
           <Line data={data} options={options}/>
        </Box>

    </Box>
}


export default TagsGraphNbByDay